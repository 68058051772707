import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import CopyRight from "./CopyRight";
import { signUpAsync } from "./services/signUpService";
import { useHistory } from 'react-router-dom';
import { isValidPassword, getPasswordInvalidText } from "./services/passwordValidator";
import { signInAsync } from "./services/signInService";

import PasswordTextField from "./PasswordTextField";

export default function AccountPage({
    isAuthenticated, setIsAuthenticated, authenticatedEmail
}) {
    const [password1, setPassword1] = React.useState('')
    const [password1Error, setPassword1Error] = React.useState(false)
    const [password2, setPassword2] = React.useState('')
    const [password2Error, setPassword2Error] = React.useState(false)
    const [changePasswordStep, setChangePasswordStep] = React.useState(false)
    const [failed, setFailed] = React.useState(false)
    const history = useHistory()

    if (!isAuthenticated) {
        history.push('/')
    }

    const _handleFormSubmit = (event) => {
        event.preventDefault()
    }

    const _handleConfirmChangePasswordClicked = () => {
        if (!isValidPassword(password1)) {
            setPassword1Error(true)
            return
        }

        if (!isValidPassword(password2)) {
            setPassword2Error(true)
            return
        }

        (async() => {
            if (!await signInAsync(authenticatedEmail, password1)) {
                setFailed(true)
                return 
            }
            await signUpAsync(authenticatedEmail, password2)
            setChangePasswordStep(false)
        })()
    }

    const _handleCancelPasswordClicked = () => {
        setPassword1('')
        setPassword2('')
        setPassword1Error(false)
        setPassword2Error(false)
        setChangePasswordStep(false)
        setFailed(false)
    }

    const _handleChangePasswordClicked = () => {
        setChangePasswordStep(true)
    }

    const _handlePassword1Changed = (event) => {
        setFailed(false)
        setPassword1Error(false)
        const newValue = event.target.value
        setPassword1(newValue)
    }

    const _handlePassword2Changed = (event) => {
        setFailed(false)
        setPassword2Error(false)
        const newValue = event.target.value
        setPassword2(newValue)
    }

    const _handleSignOutClicked = () => {
        setPassword1('')
        setPassword2('')
        setPassword1Error(false)
        setPassword2Error(false)
        setChangePasswordStep(false)
        setIsAuthenticated(false)
    }

    return (
        <React.Fragment>
            { !changePasswordStep ? 
                <Typography variant="h5">Welkom</Typography> :
                <Typography variant="h5">Wachtwoord wijzigen</Typography>
            }
            
            <Typography variant="h6">{authenticatedEmail}</Typography>
            {!failed ? null :
                <Paper style={{ padding: '10px', backgroundColor: '#ffecec' }}>
                    <Typography variant="caption">Je hebt een incorrect huidig wachtwoord ingevoerd.</Typography>
                </Paper>
            }

            { changePasswordStep ? 
                <div>
                    <form noValidate style={{ display: "block" }} onSubmit={_handleFormSubmit}>
                        <PasswordTextField
                            passwordError={password1Error}
                            onChangeCallback={_handlePassword1Changed}
                            password={password1}
                            label="Huidig wachtwoord"
                            passwordErrorMessage={getPasswordInvalidText()}
                        />
                        <PasswordTextField
                            passwordError={password2Error}
                            onChangeCallback={_handlePassword2Changed}
                            password={password2}
                            label="Nieuw wachtwoord"
                            passwordErrorMessage={getPasswordInvalidText()}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "20px", marginBottom: "10px" }}
                            onClick={_handleConfirmChangePasswordClicked}
                        >
                            Aanpassen
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            style={{ marginTop: "20px", marginBottom: "10px" }}
                            onClick={_handleCancelPasswordClicked}
                        >
                            Annuleren
                        </Button>
                        
                    </form>
                </div> :
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: '300px' }}>
                    <Button onClick={_handleChangePasswordClicked}>
                        Wachtwoord wijzigen
                    </Button>
                    <Button onClick={_handleSignOutClicked}>
                        Uitloggen
                    </Button>
            </div>
            }
            <div style={{ marginTop: "40px" }}>
                <CopyRight />
            </div>
        </React.Fragment>
    );
}
