import { get } from "./storageService"
import { hashAsync } from "./hashService"

const signInAsync = async(email, password) => {
    const normalizedEmail = email.toLowerCase()
    const hashedEmail = await hashAsync(normalizedEmail)
    const hashedPassword = await hashAsync(password)

    const storedPassword = get(hashedEmail)
    console.log(`${hashedEmail} ${hashedPassword} ${storedPassword}`)
    return hashedPassword === storedPassword
}

export { signInAsync }