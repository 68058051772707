import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CopyRight from "./CopyRight";
import { useHistory } from "react-router-dom";
import { isValidEmail } from "./services/emailValidator";
import { isValidPassword, getPasswordInvalidText } from "./services/passwordValidator";
import Paper from '@material-ui/core/Paper';
import { canResetPasswordOfThisEmailAsync } from './services/resetPasswordService'
import { signUpAsync } from "./services/signUpService";
import PasswordTextField from "./PasswordTextField";

export default function ResetPasswordPage() {
    const history = useHistory()
    const [email, setEmail] = React.useState('')
    const [emailError, setEmailError] = React.useState(false)

    const [password, setPassword] = React.useState('')
    const [passwordError, setPasswordError] = React.useState(false)

    const [failed, setFailed] = React.useState(false)
    const [isStep2, setIsStep2] = React.useState(false)

    const _handleFormSubmit = (event) => {
        event.preventDefault()

        if (!isValidPassword(password)) {
            setPasswordError(true)
            return
        }

        (async() => {
            await signUpAsync(email, password)
            history.push('/')
        })()
        
    }

    const _handleEmailChanged = (event) => {
        setEmailError(false)
        setFailed(false)
        const newValue = event.target.value
        setEmail(newValue)
    }

    const _handlePasswordChanged = (event) => {
        setPasswordError(false)
        setFailed(false)
        const newValue = event.target.value
        setPassword(newValue)
    }

    const _handleOnSendButtonClicked = () => {
        if (!isValidEmail(email)) {
            setEmailError(true)
            return
        }

        (async() => {
            if (!await canResetPasswordOfThisEmailAsync(email)) {
                setFailed(true)
                setEmail('')
                return
            }
            setIsStep2(true)
        })()
    }

    return (
        <React.Fragment>
            <div style={{ marginBottom: "20px" }}>
                <Typography variant="h4">Wachtwoord vergeten</Typography>
                <br/>
                {isStep2 ? null : 
                    <Typography variant="body1">Weet je het wachtwoord niet meer? Vul hieronder je e-mailadres in. </Typography>
                }
            </div>
            {!failed ? null :
                <Paper style={{ padding: '10px', backgroundColor: '#ffecec' }}>
                    <Typography variant="caption">Er is geen account gevonden met dit e-mailadres.</Typography>
                </Paper>
            }
            <div>
                <form noValidate style={{ display: "block" }} onSubmit={_handleFormSubmit}>
                    {isStep2 ? null : 
                        <TextField
                            error={emailError}
                            helperText={emailError ? "Het ingevulde e-mailadres is niet correct.": ""}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            id="email"
                            label="E-mailadres"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={_handleEmailChanged}
                            value={email}
                        />
                    }
                    {!isStep2 ? null : 
                        <PasswordTextField
                            passwordError={passwordError}
                            onChangeCallback={_handlePasswordChanged}
                            password={password}
                            passwordErrorMessage={getPasswordInvalidText()}
                        />
                    }
                    {isStep2 ? null : 
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "20px", marginBottom: "10px" }}
                            onClick={_handleOnSendButtonClicked}
                        >
                            Verzenden
                        </Button>
                    }
                    {!isStep2 ? null : 
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "20px", marginBottom: "10px" }}
                        >
                            Reset wachtwoord
                        </Button>
                    }
                </form>
            </div>
            <div style={{ marginTop: "40px" }}>
                <CopyRight />
            </div>
        </React.Fragment>
    );
}
