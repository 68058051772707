import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CreateIcon from '@material-ui/icons/Create';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import CopyRight from "./CopyRight";
import { signUpAsync } from "./services/signUpService";
import { isValidEmail } from "./services/emailValidator";
import { useHistory } from 'react-router-dom';
import { isValidPassword, getPasswordInvalidText } from "./services/passwordValidator";

import PasswordTextField from "./PasswordTextField";

export default function SignUpPage() {
    const [password1, setPassword1] = React.useState('')
    const [password1Error, setPassword1Error] = React.useState(false)
    const [password2, setPassword2] = React.useState('')
    const [password2Error, setPassword2Error] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [emailError, setEmailError] = React.useState(false)
    const history = useHistory()

    const _handleFormSubmit = (event) => {
        event.preventDefault()

        if (!isValidEmail(email)) {
            setEmailError(true)
            return
        }

        if (!isValidPassword(password1)) {
            setPassword1Error(true)
            return
        }

        if (password1 !== password2) {
            setPassword2Error(true)
            return
        }

        (async() => {
            await signUpAsync(email, password1)
            history.push('/')
        })()
    }

    const _handleEmailChanged = (event) => {
        setEmailError(false)
        const newValue = event.target.value
        setEmail(newValue)
    }

    const _handlePassword1Changed = (event) => {
        setPassword1Error(false)
        const newValue = event.target.value
        setPassword1(newValue)
    }

    const _handlePassword2Changed = (event) => {
        setPassword2Error(false)
        const newValue = event.target.value
        setPassword2(newValue)
    }

    return (
        <React.Fragment>
            <div style={{ display: "inline-flex", marginBottom: "20px" }}>
                <Avatar style={{ marginRight: "20px" }} variant="rounded">
                    <CreateIcon />
                </Avatar>
                <Typography variant="h5">Maak een account aan</Typography>
            </div>
            <div>
                <form noValidate style={{ display: "block" }} onSubmit={_handleFormSubmit}>
                    <TextField
                        error={emailError}
                        helperText={emailError ? "Het ingevulde e-mailadres is niet correct.": ""}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="email"
                        label="E-mailadres"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={_handleEmailChanged}
                    />
                    <PasswordTextField
                        passwordError={password1Error}
                        onChangeCallback={_handlePassword1Changed}
                        password={password1}
                        passwordErrorMessage={getPasswordInvalidText()}
                    />
                    <PasswordTextField
                        passwordError={password2Error}
                        onChangeCallback={_handlePassword2Changed}
                        password={password2}
                        label="Wachtwoord bevestiging"
                        passwordErrorMessage="De wachtwoorden moeten hetzelfde zijn"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "20px", marginBottom: "10px" }}
                    >
                        Maak een account aan
                    </Button>
                    
                </form>
            </div>
            <div style={{ marginTop: "40px" }}>
                <CopyRight />
            </div>
        </React.Fragment>
    );
}
