import React from 'react'
import LoginForm from "./LoginForm";
import "./styles.css";
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import SignUpPage from "./SignUpPage";
import ResetPasswordPage from "./ResetPasswordPage";
import AccountPage from "./AccountPage";
import Container from "@material-ui/core/Container";

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [authenticatedEmail, setAuthenticatedEmail] = React.useState('')

  return (
    <Router>
      <div className="App">
        <Container maxWidth="xs">
          <Switch>
            <Route path="/signup">
              <SignUpPage />
            </Route>
            <Route path="/account">
              <AccountPage 
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                authenticatedEmail={authenticatedEmail}
              />
            </Route>
            <Route path="/resetpassword">
              <ResetPasswordPage />
            </Route>
            <Route path="/">
              <LoginForm 
                isAuthenticated={isAuthenticated}
                setIsAuthenticated={setIsAuthenticated}
                setAuthenticatedEmail={setAuthenticatedEmail}
              />
            </Route>
          </Switch>
        </Container>
      </div>
    </Router>
  );
}
