import { hashAsync } from "./hashService"
import { set } from './storageService'

const signUpAsync = async(email, password) => {
    const normalizedEmail = email.toLowerCase()
    const hashedEmail = await hashAsync(normalizedEmail)
    const hashedPassword = await hashAsync(password)

    set(hashedEmail, hashedPassword)
}

export { signUpAsync }