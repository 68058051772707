import { hashAsync } from "./hashService"
import { get } from "./storageService"

const canResetPasswordOfThisEmailAsync = async(email) => {
    const normalizedEmail = email.toLowerCase()
    const hashedEmail = await hashAsync(normalizedEmail)
    const hashedPassword = get(hashedEmail)

    return !!hashedPassword
}

export {canResetPasswordOfThisEmailAsync}