import React from "react";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CopyRight from "./CopyRight";
import { Link, useHistory } from "react-router-dom";
import { isValidEmail } from "./services/emailValidator";
import { signInAsync } from "./services/signInService";
import { isValidPassword, getPasswordInvalidText } from "./services/passwordValidator";
import Paper from '@material-ui/core/Paper';
import PasswordTextField from "./PasswordTextField";

export default function LoginForm({
    setIsAuthenticated,
    setAuthenticatedEmail
}) {
    const history = useHistory()
    const [email, setEmail] = React.useState('')
    const [emailError, setEmailError] = React.useState(false)

    const [password, setPassword] = React.useState('')
    const [passwordError, setPasswordError] = React.useState(false)

    const [signInFailed, setSignInFailed] = React.useState(false)

    const _handleFormSubmit = (event) => {
        event.preventDefault()

        if (!isValidEmail(email)) {
            setEmailError(true)
            return
        }

        if (!isValidPassword(password)) {
            setPasswordError(true)
            return
        }

        (async() => {
            if (! await signInAsync(email, password)) {
                setEmail('')
                setPassword('')
                setSignInFailed(true)
                return
            }

            setIsAuthenticated(true)
            setAuthenticatedEmail(email)
            history.push('/account')
            
        })()
    }

    const _handleEmailChanged = (event) => {
        setEmailError(false)
        setSignInFailed(false)
        const newValue = event.target.value
        setEmail(newValue)
    }

    const _handlePasswordChanged = (event) => {
        setPasswordError(false)
        setSignInFailed(false)
        const newValue = event.target.value
        setPassword(newValue)
    }

    return (
        <React.Fragment>
            <div style={{ display: "inline-flex", marginBottom: "20px" }}>
                <Avatar style={{ marginRight: "20px" }} variant="rounded">
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h4">Inloggen</Typography>
            </div>
            {!signInFailed ? null :
                <Paper style={{ padding: '10px', backgroundColor: '#ffecec' }}>
                    <Typography variant="caption">De combinatie van e-mailadres en wachtwoord is niet geldig.</Typography>
                </Paper>
            }
            <div>
                <form noValidate style={{ display: "block" }} onSubmit={_handleFormSubmit}>
                    <TextField
                        error={emailError}
                        helperText={emailError ? "Het ingevulde e-mailadres is niet correct.": ""}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="email"
                        label="E-mailadres"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={_handleEmailChanged}
                        value={email}
                    />
                    <PasswordTextField
                        passwordError={passwordError}
                        onChangeCallback={_handlePasswordChanged}
                        password={password}
                        passwordErrorMessage={getPasswordInvalidText()}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "20px", marginBottom: "10px" }}
                    >
                        Inloggen
                    </Button>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Link to='/resetpassword'>
                            Wachtwoord vergeten?
                        </Link>
                        <Link to='/signup'>
                            {"Maak een account aan"}
                        </Link>
                    </div>
                </form>
            </div>
            <div style={{ marginTop: "40px" }}>
                <CopyRight />
            </div>
        </React.Fragment>
    );
}
